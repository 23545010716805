import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    chatSelected: false,
    userSelected: false
  },
  mutations: {
    chatSelected(state, value) {
      state.chatSelected = value;
    },
    userSelected(state, value) {
      state.userSelected = value;
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
  }
});
