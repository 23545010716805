exports.aclRules = function (acl) {
    var _const = require('@/utils/Constants');

    acl.rule('edit-users', () => {
      return localStorage.getItem(_const.default.KEY.USER.ROLE) == _const.default.USER.ROLE.ADMIN;
    });
    acl.rule('add-user', () => {
      return localStorage.getItem(_const.default.KEY.USER.ROLE) == _const.default.USER.ROLE.ADMIN;
    });
    acl.rule('edit-user', () => {
      return localStorage.getItem(_const.default.KEY.USER.ROLE) == _const.default.USER.ROLE.ADMIN;
    });
    acl.rule('delete-user', () => {
      return localStorage.getItem(_const.default.KEY.USER.ROLE) == _const.default.USER.ROLE.ADMIN;
    });
};
