import Vue from 'vue';
import acl from 'vue-browser-acl';
import Auth from '@/config/Auth';
import VueI18n from 'vue-i18n';
import VueCookie from 'vue-cookie';
import App from './App.vue';
import router from './config/Router';
import store from './config/Store';
import { defaultLocale, languages } from './languages/index';

import 'prismjs/themes/prism.css';

Vue.config.productionTip = false;

export const eventBus = new Vue();

window.auth = new Auth();

Vue.use(VueCookie);
Vue.use(VueI18n);
Vue.use(acl, null, require('./config/Acl').aclRules, {router});

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: Object.assign(languages)
});

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app');
