import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/session/login'
    },
    {
      path: '/login',
      redirect: '/session/login'
    },
    {
      path: '/logout',
      redirect: '/session/logout'
    },
    {
      path: '/session',
      redirect: '/session/login'
    },
    {
      path: '/session',
      name: 'Session',
      component: () => import('@/views/session/session.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/views/session/login/login.vue')
        },
        {
          path: 'logout',
          name: 'logout',
          component: () => import('@/views/session/logout/logout.vue')
        },
        {
          path: 'reset',
          name: 'forgot-password',
          component: () => import('@/views/session/reset-password/reset-password.vue')
        }
      ]
    },
    {
      path: '/chats',
      name: 'chats',
      component: () => import('@/views/chats/chats.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/users.vue'),
      meta: {
        requiresAuth: true,
        can: 'edit-users',
        fail: '/session/logout'
      }
    },
    {
      path: '/results/new',
      name: 'addResults',
      component: () => import('@/views/results/results.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/users/:uuid',
      name: 'user',
      component: () => import('@/views/users/users.vue'),
      meta: {
        requiresAuth: true,
        can: 'edit-users',
        fail: '/session/logout'
      }
    },
    {
      path: '*',
      name: '404',
      component: () => import('@/views/page-not-found/page-not-found.vue'),
      meta: { requiresAuth: true }
    }
  ]
});
