export const ObjectHelper = {
    construct() {

        /*
            Define method .forEach for an object to iterate it as an array
            => object.forEach(function (item, key) { }
        */
        if (!Object.prototype.forEach) {
            Object.defineProperty(Object.prototype, 'forEach', {
                value: function (callback, thisArg) {
                    if (this == null) {
                        throw new TypeError('Not an object');
                    }
                    thisArg = thisArg || window;
                    for (var key in this) {
                        if (this.hasOwnProperty(key)) {
                            callback.call(thisArg, this[key], key, this);
                        }
                    }
                }
            });
        }
    },

    exists: function (object) {
        return object !== undefined && object !== null;
    },

    hasKey: function (key, object) {
        return (key in object);
    },

    hasNoKey: function (key, object) {
        return !this.hasKey(key, object);
    },

    doesNotExist: function (object) {
        return !(object !== undefined && object !== null);
    },

    removeDuplicates: function (array) {
        return array.filter(this.makeUnique);
    },

    filterArrayByKeyValue: function (array, property, value) {
        return array.filter(function (guide) {
            return guide[property] === value;
        });
    },

    makeUnique: function (value, index, self) {
        return self.indexOf(value) === index;
    },

    objectToArray(obj) {
        let values = [];

        if (obj !== undefined)
            for (let key in obj)
                if (obj.hasOwnProperty(key))
                    values.push(obj[key]);

        return values;
    },

    lastItemOfArray(arr) {
      return arr[arr.length-1];
    },

    firstItemOfArray(arr) {
      return arr[0];
    },

    objectSize: function (obj) {
        var size = 0,
            key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    },

    notEmpty(object) {
        return (ObjectHelper.exists(object) && object !== '')
    },

    mapKeyToArray(array, key) {

        let rows = [];
        array.forEach((row) => {
            if (ObjectHelper.exists(row[key]) && ObjectHelper.notEmpty(row))
                rows.push(row[key]);
        });
        return rows;
    }
};

export default ObjectHelper;
