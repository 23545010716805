import global from './global/en.json';
import session from './session/en.json';
import buttons from './buttons/en.json';
import placeholders from './input/placeholders/en.json';
import errors from './input/errors/en.json';
import labels from './input/labels/en.json';
import navigation from './navigation/en.json';
import chats from './chats/en.json';
import results from './results/en.json';
import notifications from './notifications/en.json';
import users from './users/en.json';
import page_not_found from './page-not-found/en.json';

export default {
  global,
  session,
  navigation,
  buttons,
  results,
  chats,
  notifications,
  users,
  page_not_found,
  input: {
    labels,
    placeholders,
    errors
  }
};
