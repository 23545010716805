import router from '@/config/Router';
import CONST from '@/utils/Constants';
import ObjectHelper from "@/helpers/ObjectHelper";

class Auth {
    constructor() {
        router.beforeEach((to, from, next) => {
            if (to.matched.some(record => record.meta.requiresAuth)) {

                if (!Auth.isLoggedIn()) {
                  next({path: CONST.ROUTE.SESSION.LOGIN});
                } else {
                  next();
                }
            } else {
                next();
            }
        });
    }

    static logOut() {
      localStorage.clear();
      sessionStorage.clear();

      router.push(CONST.ROUTE.SESSION.LOGIN);
    }

    static isLoggedIn() {
      return ObjectHelper.exists(localStorage.getItem(CONST.KEY.AUTH.ACCESS_TOKEN));
    }
}

export default Auth;
