const CONST = {
  API: {
    ROUTE: {
      SESSION: {
          LOGIN: "/auth/login",
          LOGOUT: "/auth/logout",
          RESET_PASSWORD: "/auth/reset",
          RESET_PASSWORD_CONFIRM: "/auth/reset/confirm"
      },
      BARCODES: {
        GENERATE: "/v1/barcodes/:id/generate",
        CHECK: "/v1/barcodes/:barcode/check"
      },
      CHATS: {
          OVERVIEW: "/v1/channels",
          SEND: "/v1/channels/notify/:identifier",
          DETAIL: "/v1/channels/identify/:identifier",
          UNREAD: "/v1/channels/identify/:identifier/unread",
          READ: "/v1/channels/identify/:identifier/read"
      },
      USERS: {
        OVERVIEW: "/v1/users",
        NEW: "/auth/register",
        DETAIL: "/v1/users/:uuid",
        DELETE: "/v1/users/:uuid",
        ADD_ROLE: "/v1/users/:uuid/assign",
        DELETE_ROLE: "/v1/users/:uuid/revoke"
      },
      RESULTS: {
        NEW: "/v1/barcodes/:id/advices"
      }
    },
    EXCEPTION: {
      VALIDATION_ERROR: 404,
      AUTH_EXPIRED: 402,
      INVALID_LOGIN: 401
    }
  },
  ROUTE: {
    SESSION: {
      LOGIN: "/session/login",
      LOGOUT: "/session/logout",
      RESET_PASSWORD: "/session/reset-password",
      RESET_PASSWORD_CONFIRM: "/session/reset-password/confirm"
    },
    CHATS: {
      OVERVIEW: "/chats"
    },
    USERS: {
      OVERVIEW: "/users",
      EDIT: "/users/:uuid",
      NEW: "/users/new"
    }
  },
  EVENTS: {
    USER: {
      ADDED: 'user-added',
      UPDATED: 'user-updated',
      DELETED: 'user-deleted'
    },
    CHAT: {
      SELECTED: 'chat-selected',
      READ: 'chat-read'
    }
  },
  RESPONSE: {
    CODE: {
      ERROR: 404,
      SUCCES: 200
    }
  },
  USER: {
    ROLE: {
      DEFAULT: 'user',
      SUPERADMIN: 'superadmin',
      ADMIN: 'admin',
      USER: 'user'
    },
    LOCKED: {
      EMAIL: 'danny@hellosunshine.nl'
    }
  },
  KEY: {
    AUTH : {
      ACCESS_TOKEN: 'access_token',
      EMAIL: 'email',
      PASSWORD: 'password'
    },
    USER: {
      ID: 'id',
      ROLE: 'role'
    },
    REQUEST: {
      AUTHORIZATION: 'Authorization',
      BEARER: 'Bearer ',
      ACCEPT: 'Accept',
      CONTENT_TYPE: 'Content-Type',
      APP_KEY: 'Application-Key',
      APP_SECRET: 'Application-Secret'
    },
    TABLE: {
      TYPE: {
        CELL: {
          TYPE: "type",
          TEXT: 'text',
          ROLE: 'role',
          DEFAULT: 'text'
        }
      }
    }
  },
  VALUE: {
    REQUEST: {
      ACCEPT_JSON: 'application/json',
      ACCEPT_MULTIPART: "multipart/form-data"
    },
    ROLES: [
      {
        NAME: 'Superadmin',
        VALUE: 'superadmin',
        ID: 1
      },
      {
        NAME: 'Admin',
        VALUE: 'admin',
        ID: 2
      },
      {
        NAME: 'User',
        VALUE: 'user',
        ID: 3
      }
    ]
  }
};
export default CONST;
